<template>
  <c-box
    pos="relative"
    w="100%"
    :bg="['#F2F2F2', 'white']"
    h="100%"
    :min-h="['calc(100vh - 64px)', '100%']"
    pb="8px"
  >
    <Portal to="breadcrumb">
      <BreadcrumbPath
        px="90px"
        py="20px"
        :paths="breadcrumbPath"
      />
    </Portal>
    <c-box
      :box-shadow="['none', '2px 2px 10px rgba(0, 0, 0, 0.15)']"
      :border-radius="['none', '12px']"
      w="100%"
      :px="['8px', '85px']"
      :pt="['8px', '0']"
      :pb="['0', '30px']"
    >
      <c-flex
        :display="['none', 'flex']"
        width="100%"
        justify-content="space-between"
        align-items="center"
        padding-block="30px"
      >
        <BaseText
          size-mobile="20px"
          size-desktop="28px"
          white-space="nowrap"
        >
          Nilai Gizi
        </BaseText>
      </c-flex>

      <c-box
        position="relative"
        width="100%"
        :padding-inline="['0px', '20px']"
        :padding-block="['12px', '0px']"
        background-color="#FFF"
      >
        <GlossaryContainer
          route-name="nutritionist.glossaries-detail"
          :breakpoint="currentBreakpoint"
        />
      </c-box>
    </c-box>
  </c-box>
</template>

<script>
import BreadcrumbPath from '@/components/elements/breadcrumb-path.vue'
import BaseText from '@/components/elements/base-text.vue'
import GlossaryContainer from '@/components/glossary/glossary-container.vue'
import { mapState } from 'vuex'
import mixinsCheckBreakpoints from '@/mixins/mixins-check-breakpoints'

export default {
  name: 'GlossariesPage',
  components: {
    BreadcrumbPath,
    BaseText,
    GlossaryContainer,
  },
  mixins: [
    mixinsCheckBreakpoints(),
  ],
  data() {
    return {}
  },
  computed: {
    ...mapState({
      user: (s) => s.auth.user,
    }),
    isClient() {
      return this.user && this.user.role === 'client' 
    },
    breadcrumbPath() {
      if (this.isClient) {
        return [
          { label: 'Dashboard', href: { name: 'client.index' } },
          { label: 'Nilai Gizi', isCurrent: true },
        ]
      }
      return [
        { label: 'Beranda', href: { name: 'nutrisionist.managementClient' } },
        { label: 'Nilai Gizi', isCurrent: true },
      ]
    },
  },
  mounted() {
    if (this.$route.query?.posthog) {
      this.$posthog.capture(this.$route.query.posthog)
      this.$router.replace({ name: 'client.glossaries' })
    }
  },
}
</script>
